<template>
	<div class="container-sm my-2">
		<div class="row">
			<div class="col-12 mx-auto">
				<div class="header">
					<el-page-header
						@back="goBack"
						content="貼文管理"
						title="回首頁"
					></el-page-header>
					<el-button
						class="ms-auto"
						type="success"
						round
						@click="redirectToAddPost(null)"
						>撰寫貼文</el-button
					>
				</div>

				<el-tabs
					v-model="activeName"
					class="demo-tabs"
					@tab-click="handleClick"
				>
					<el-tab-pane :label="`草稿 ${draftCount}`" name="draft">
						<ul class="list-group list-group-flush">
							<li
								class="list-group-item py-3 flex-start"
								v-for="i in draftPosts"
								:key="i"
							>
								<h3 class="noteTitle" @click="redirectToAddPost(i.id)">
									{{ i.title == "" ? "未命名草稿" : i.title }}
								</h3>

								<!-- <template v-if="i.videoPath != ''">
									<video :src="i.videoPath" controls class="w-100 my-2"></video>
								</template>

								<p
									v-else
									class="infoFirstRow"
									v-html="sanitizeHTML(i.content)"
								></p> -->

								<p class="createDate">
									<span>{{ i.createDate }}</span>
									<span>&nbsp;創建</span>
									<el-dropdown size="small" class="ms-2">
										<i class="far fa-angle-down ms-1"></i>
										<template #dropdown>
											<el-dropdown-menu>
												<el-dropdown-item @click="redirectToAddPost(i.id)"
													>編輯筆記</el-dropdown-item
												>
												<el-dropdown-item
													><span class="text-danger" @click="removePost(i.id)"
														>刪除筆記</span
													></el-dropdown-item
												>
											</el-dropdown-menu>
										</template>
									</el-dropdown>
								</p>
							</li>
						</ul>
					</el-tab-pane>

					<el-tab-pane :label="`已發表 ${publishedCount}`" name="published">
						<ul class="list-group list-group-flush">
							<li
								class="list-group-item py-3"
								v-for="i in publishedPosts"
								:key="i"
							>
								<h4 class="noteTitle" @click="redirectToViewPost(i.id)">
									{{ i.title == "" ? "未命名草稿" : i.title }}
								</h4>

								<!-- <template v-if="i.videoPath != ''">
									<video :src="i.videoPath" controls class="w-100 my-2"></video>
								</template>

								<p
									v-else
									class="infoFirstRow"
									v-html="sanitizeHTML(i.content)"
								></p> -->
								<p class="createDate">
									<span>{{ i.createDate }}</span>
									<span>&nbsp;發表</span>
									<el-dropdown size="small" class="ms-2">
										<i class="far fa-angle-down ms-1"></i>
										<template #dropdown>
											<el-dropdown-menu>
												<el-dropdown-item @click="redirectToAddPost(i.id)"
													>編輯筆記</el-dropdown-item
												>
												<el-dropdown-item
													><span class="text-danger" @click="removePost(i.id)"
														>刪除筆記</span
													></el-dropdown-item
												>
											</el-dropdown-menu>
										</template>
									</el-dropdown>
								</p>
							</li>
						</ul>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			activeName: "draft",
			publishedPosts: [],
			draftPosts: [],
			publishedCount: 0,
			draftCount: 0,
			itemsPerPage: 9999,
			pageNumber: 1,
			notMore: false,
		};
	},
	computed: {
		c_currentUser() {
			return this.currentUser();
		},
	},
	mounted() {
		let vm = this;
		vm.loadData();
	},
	methods: {
		redirectToAddPost(id) {
			let vm = this;
			if (id == null) {
				vm.fetchAPI("Post", `${process.env.VUE_APP_URL_API}/Post/Add`)
					.then((res) => {
						console.log(res.data);
						vm.$router.push({
							name: "ChargeMeTribe_AddPost_id",
							params: { id: res.data.id },
						});
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				this.$router.push({
					path: `/ChargeMeTribe/AddPost/${id}`,
				});
			}
		},
		redirectToViewPost(id) {
			let vm = this;
			vm.$router.push({
				name: "ChargeMeTribe_Post",
				params: { id: id },
				meta: {
					fromRoute: this.$route.path,
				},
			});
		},
		loadData() {
			let vm = this;
			vm.fetchAPI(
				"GET",
				`${process.env.VUE_APP_URL_API}/Post/GetCurrentPosts/${vm.pageNumber}/${
					vm.itemsPerPage
				}/${false}`,
				vm.postInfo
			)
				.then((res) => {
					console.log(res.data);
					vm.publishedPosts = res.data.publishedPosts;
					vm.draftPosts = res.data.draftPosts;
					vm.draftCount = vm.draftPosts.length;
					vm.publishedCount = vm.publishedPosts.length;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		removePost(id) {
			let vm = this;
			this.$confirm("確定刪除此貼文?", "提示", {
				confirmButtonText: "確定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					vm.fetchAPI(
						"delete",
						`${process.env.VUE_APP_URL_API}/Post/Delete/${id}`,
						{},
						false
					).then((res) => {
						console.log(res);
						vm.publishedPosts = vm.publishedPosts.filter((x) => x.id != id);
						vm.draftPosts = vm.draftPosts.filter((x) => x.id != id);
						vm.publishedCount = vm.publishedPosts.length;
						vm.draftCount = vm.draftPosts.length;

						this.$message({
							type: "success",
							message: "刪除成功!",
						});
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消",
					});
				});
		},
		sanitizeHTML(html) {
			return html
				.replace(/(<img\s[^>]*?)\s(width|height)="[^"]*"([^>]*>)/gi, "$1$3")
				.replace(
					/<img\s([^>]*?)\s(width|height)="[^"]*"([^>]*?)>/gi,
					(match, p1, p2, p3) => {
						// 檢查是否已經有 style 屬性
						const hasStyle = /style="[^"]*"/.test(p1);
						if (hasStyle) {
							// 如果有 style 屬性，則添加 width: 100%
							return `<img ${p1.replace(
								/style="([^"]*)"/,
								(match, styleContent) => `style="${styleContent}; width: 100%"`
							)} ${p3}>`;
						} else {
							// 如果沒有 style 屬性，則創建 style 屬性
							return `<img ${p1} style="width: 100%; height: auto; aspect-ratio:1074/717;" ${p3}>`;
						}
					}
				);
		},
		goBack() {
			let vm = this;
			vm.$router.push(`/ChargeMeTribe`);
		},
	},
};
</script>

<style>
.header {
	display: flex;
	align-items: center;
}
.myNote {
	font-size: xx-large;
	font-weight: 600;
	color: black;
}

.noteTitle {
	font-size: large;
	font-weight: 600;
	text-align: start;
}

.noteTitle:hover {
	text-decoration: underline;
	cursor: pointer;
}

.infoFirstRow {
	text-align: start;
	margin-top: 0.5rem;
	color: rgba(0, 0, 0, 0.4);
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3; /* 显示的行数 */
}
.createDate {
	text-align: start;
	font-size: medium;
	padding-top: 0.5rem;
	color: rgba(0, 0, 0, 0.4);
}
</style>
